$text: #000;
$dark: #000;
$danger: #e4260e;
$gray: #e6e6e6;
$light: #fff;

@import url("animate.css");

/* This changes all the animations globally */
:root {
    --animate-duration: 400ms;
    --white: #fff;
    --default: #eee;
    --default-hover: #bbb;
    --primary: #023E96;
    --primary-hover: #2471E3;
    --primary-bg-color: #eee;
    --danger: #E4260E;
    --danger-hover: #962718;
    --black: #000;
    --black-hover: #222;
    --black-normal: #555;
    --info: #684fff;
    --box-shadow: 0 2px 6px 0 var(--black-opacity), inset 0 -1px 0 0 var(--default);
    --animation-fast: 0.8s;
    --font-family: 'Myriad';
    --font-family-bold: 'Myriad Bold';
    --font-family-light: 'Myriad Light';
    --animation-duration: 0.2s;
    --border-radius: 0px;
    --gradient: linear-gradient(to right, #2471E3, #023E96);
    --gradient-transition: opacity 0.2s ease-in-out, width 0.2s ease-in-out;
}

body {
    margin: 0;
    font-family: 'Myriad Pro', sans-serif;
    background-color: $light;
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}


// ---------------- TYPOGRAPHY ---------------------

h1,
.h1 {
    text-transform: uppercase;
    font-size: 64px;
    font-family: 'Myriad Bold';
}

h2,
.h2 {
    // text-transform: uppercase;
    font-size: 42px;
    font-family: 'Myriad Bold';
    text-transform: capitalize;
}

h3,
.h3 {
    font-size: 36px;
    font-family: 'Myriad Bold';
    text-transform: capitalize;
}

h4,
.h4 {
    text-transform: capitalize;
    font-size: 24px;
    font-family: 'Myriad Bold';
}

h5,
.h5 {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: unset;
}

h6,
.h6 {
    text-transform: capitalize;
    font-size: 14px;
    font-family: 'Myriad Bold';
}


p,
.p {
    margin: 0;
    font-family: "Myriad Pro", sans-serif;
    font-size: 16px;
    line-height: 1.3;
}

/** MOBILE DESIGN */

@media only screen and (max-width: 576px) {

    h1,
    .h1 {
        text-transform: uppercase;
        font-size: 42px;
        font-family: 'Myriad Bold';
    }

    h2,
    .h2 {
        // text-transform: uppercase;
        font-size: 38px;
        font-family: 'Myriad Bold';
        text-transform: capitalize;
    }

    h3,
    .h3 {
        font-size: 24px;
        font-family: 'Myriad Bold';
        text-transform: capitalize;
    }

    h4,
    .h4 {
        text-transform: capitalize;
        font-size: 18px;
        font-family: 'Myriad Bold';
    }

    h5,
    .h5 {
        text-transform: capitalize;
        font-size: 14px;
        font-weight: unset;
    }

    h6,
    .h6 {
        text-transform: capitalize;
        font-size: 12px;
        font-family: 'Myriad Bold';
    }


    p,
    .p {
        margin: 0;
        font-family: "Myriad Pro", sans-serif;
        font-size: 14px;
    }
}

/*Margin*/
.mt-5 {
	margin-top: 5px !important;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-15 {
	margin-top: 15px !important;
}

.mt-20 {
	margin-top: 20px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-60 {
	margin-top: 60px;
}

.mb-5 {
	margin-bottom: 5px !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-60 {
	margin-bottom: 60px !important;
}

.mr-5 {
	margin-right: 5px !important;
}

.mr-10 {
	margin-right: 10px !important;
}

.mr-20 {
	margin-right: 20px !important;
}

.mr-30 {
	margin-right: 30px !important;
}

.mr-60 {
	margin-right: 60px !important;
}

.ml-5 {
	margin-left: 5px !important;
}

.ml-10 {
	margin-left: 10px !important;
}

.ml-30 {
	margin-left: 30px !important;
}

.ml-60 {
	margin-left: 60px !important;
}

.pt-20 {
	padding-top: 20px !important;
}

.p-20 {
	padding: 20px !important;
}

.w-auto {
	width: auto !important;
}

.w-320 {
	width: 320px !important;
}

.w-240 {
	width: 240px !important;
}

.w-120 {
	width: 120px !important;
}

.w-60 {
	width: 60px !important;
}

.w-30p {
	width: 30% !important;
}

.w-50p {
	width: 50% !important;
}

.w-60p {
	width: 60% !important;
}

.h-20 {
	height: 20px !important;
}

/*Text*/
.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.text-left {
	text-align: left !important;
}

.text-justify {
	text-align: justify !important;
}

// ---------------- TYPOGRAPHY ---------------------

// ---------------- TEXT COLOR ---------------------

.text-light {
    color: $light;
}

.text-dark {
    color: $dark;
}

.text-danger {
    color: $danger;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-right {
    text-align: right !important;
}

// ---------------- TEXT COLOR ---------------------

/** Display */
.d-none {
    display: none !important;
}

// ------------------- BUTTON -----------------------
button,
.clickable {
    cursor: pointer;
    background: unset;
    border: unset;
}

.btn {
    padding: 7px 20px;
    cursor: pointer;
    border: unset;
    transition: all 0.2s ease-in-out;
    font-family: 'Myriad Bold';
}

.btn .start-icon {
    float: left;
}

.btn .end-icon {
    float: right;
    margin-right: -5px;
    margin-left: 10px;
    margin-top: 2px;

}

.btn-rounded {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.btn-primary {
    color: var(--white);
}

.btn-primary-dark {
    background: $dark;
    color: $light;
}

.btn-primary-dark:hover,
.btn-primary-dark:active {
    background-color: var(--black-opacity);
}

.btn-outlined-dark {
    border: 2px solid $dark;
    background: unset;
    color: $dark;
}

.btn-outlined-dark:hover,
.btn-outlined-dark:active {
    background-color: $dark;
    color: $light;
}

.btn-outlined-light {
    border: 2px solid $light;
    background: unset;
    color: $light;
}

.btn-outlined-light:hover,
.btn-outlined-light:active {
    background-color: $light;
    color: $dark;
}

.btn-danger {
    background-color: $danger;
    color: $light;
    padding: 0.5em 2em;
}

.btn-gray {
    background-color: $gray;
    color: black;
}

.btn-purple {
    background-color: #5457ff;
    color: white;
}

// ------------------- BUTTON -----------------------

// ------------------- TEXT ------------------
.bold {
    font-family: 'Myriad Bold';
}

.bolder {
    font-weight: bolder;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

// ------------------- TEXT ------------------

// ------------------- WIDTH ------------------------
.w-100 {
    width: 100%;
}

.section-max-width {
    width: 100%;
    max-width: 1440px;
}

// ------------------- WIDTH ------------------------

// ------------------- MARGIN -----------------------

.m-0 {
    margin: 0;
}

.mb-20 {
    margin-bottom: 20px;
}

// ------------------- MARGIN -----------------------

// ------------------- PADDING -----------------------
.p-0 {
    padding: 0;
}

// .p-1 {padding: 1em}
// .p-2 {padding: 2em}
// .p-3 {padding: 3em}
// .p-4 {padding: 4em}
// .p-5 {padding: 5em}
// ------------------- PADDING -----------------------

// ------------------ BACKGROUND-COLOR ---------------
.bg-dark {
    background-color: dark;
}

.background-dark-mask-7 {
    background-color: rgba(0, 0, 0, .7);
}

.background-dark-mask-5 {
    background-color: rgba(0, 0, 0, .3);
}

// --------------- BACKGROUND-COLOR ------------------

// --------------- FLOATING --------------------------
.float-right {
    float: right;
}

.float-left {
    float: left;
}

.clear-both {
    clear: both;
}

// --------------- FLOATING --------------------------

/** Margin */
.mt-0 {
    margin-top: 0 !important;
}

/** Animate.css */
.animate__delay-0-5s {
    animation-delay: 0.5s;
}

.animate__delay-0-8s {
    animation-delay: 0.8s;
}

.animate__delay-1s {
    animation-delay: 1s;
}

.animate__delay-1-2s {
    animation-delay: 1.2s;
}

.animate__delay-1-5s {
    animation-delay: 1.5s;
}

.animate__delay-1-8s {
    animation-delay: 1.8s;
}

.animate__delay-0 {
    animation-delay: 0s;
}

/** Background */
.box-background {
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    transition: all 0.2s linear;
}

.box-background.box-background-blur {
    filter: blur(8px);
}

/** THEMES */

// header themes
.header-dark-mode {
    * {
        color: black;
    }

    img {
        filter: brightness(0);
    }
}

.header-light-mode {
    * {
        color: white;
    }
}

// footer themes

.footer-dark-mode {
    * {
        color: black;
    }
}

.footer-light-mode {
    * {
        color: white;
    }
}

// pagination themes

.pagination-dark-mode {
    * {
        color: black;
    }
}

.pagination-light-mode {
    * {
        color: white;
    }

    img {
        filter: brightness(1) invert(1);
    }
}

// button navigation themes 

.btn-nav-dark-mode {
    * {
        color: black;
    }

    &:disabled {
        * {
            color: grey;
        }
    }
}

.btn-nav-light-mode {
    * {
        color: white;
    }

    &:disabled {
        * {
            color: grey;
        }
    }
}


/** TABLET DESIGN */

@media only screen and (max-width: 992px) {}

/** MOBILE DESIGN */

@media only screen and (max-width: 576px) {
    body {
        overflow-y: auto;
        overflow-x: hidden;
    }
}